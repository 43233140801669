import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
import config from '../../config';
import MediaQuery from 'react-responsive';
import { StaticImage } from 'gatsby-plugin-image';
import video_mp4 from '../assets/images/banner.mp4';
import video_sm_mp4 from '../assets/images/banner_sm.mp4';
import banner from '../assets/images/banner.jpg';
import banner_sm from '../assets/images/banner_sm.jpeg';
import { graphql } from 'gatsby';
const IndexPage = ({ data }) => {
  return (
    <>
      <Layout>
        <MediaQuery minWidth={1001}>
          <figure
            className="static_image"
            style={{ width: '200%', margin: '0 -50%', textAlign: 'center' }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: '70%',
                overflow: 'hidden',
              }}
              loading="lazy"
              poster={banner}
            >
              <source src={video_mp4} type="video/mp4" />
            </video>
          </figure>
        </MediaQuery>
        <MediaQuery minWidth={401}>
          <figure
            className="static_image"
            style={{ width: '200%', margin: '0 -50%', textAlign: 'center' }}
          >
            <div
              style={{
                backgroundColor: '#000000',
              }}
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  height: '1000px',
                  overflow: 'hidden',
                }}
                loading="lazy"
                poster={banner}
              >
                <source src={video_mp4} type="video/mp4" />
              </video>
            </div>
          </figure>
        </MediaQuery>
        <MediaQuery maxWidth={400}>
          <figure
            className="static_image"
            style={{ width: '200%', margin: '0 -50%', textAlign: 'center' }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                height: '820px',
                overflow: 'hidden',
              }}
              loading="lazy"
              poster={banner_sm}
            >
              <source src={video_sm_mp4} type="video/mp4" />
            </video>
          </figure>
        </MediaQuery>
        <section id="banner">
          <div className="inner">
            <MediaQuery minWidth={401}>
              <StaticImage
                src="../assets/images/logo.png"
                style={{
                  width: '300px',
                  marginBottom: '40px',
                }}
                alt="logo"
              />
            </MediaQuery>
            <MediaQuery maxWidth={400}>
              <StaticImage
                src="../assets/images/logo_white.png"
                style={{
                  width: '175px',
                  marginBottom: '40px',
                }}
                alt="logo"
              />
            </MediaQuery>
            <br />
            <h2>{config.heading}</h2>
            <div className="text">
              <p>
                {config.subHeading1}
                <br />
                {config.subHeading2}
                <br />
                {config.subHeading3}
              </p>
            </div>
          </div>

          <Scroll type="id" element="news">
            <a href="#news" className="more"></a>
          </Scroll>
        </section>

        <section id="news" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>NEWS</h2>
              <br />
              <h6>ニュース</h6>
              <div className="posts">
                {data.allMicrocmsNews.edges.map(({ node: post }) => (
                  <section className="news_post" key={post.id}>
                    <div className="image">
                      <h3>{post.date}</h3>
                    </div>
                    <div className="content">
                      <p>{post.title}</p>
                    </div>
                  </section>
                ))}
              </div>
            </header>
          </div>
        </section>

        <section id="service" className="wrapper style2 special">
          <div className="inner">
            <header className="major">
              <h2>SERVICE</h2>
              <br />
              <h6>サービス</h6>
            </header>
          </div>
        </section>
        <section className="wrapper alt style2">
          <section className="spotlight">
            <div className="image">
              <StaticImage
                src="../assets/images/pic01.jpg" // 相対パス
                alt="pitto"
              />
            </div>
            <div className="content">
              <h2>pitto</h2>

              <p>
                クリニック経営者やスタッフにとって、医療品の在庫管理は悩みの種。「pitto」を導入すれば、バーコードを「pitto」するだけで面倒な在庫管理を代わりに行い、面倒な業務や経営コストの削減につながります。
              </p>
              <a href="https://pitto.mappin.jp/" className="button fit primary">
                詳細はここをクリック
              </a>
            </div>
          </section>
        </section>

        <section id="member" className="wrapper style3 special">
          <div className="inner">
            <header className="major">
              <h2>MEMBER</h2>
              <br />
              <h6>メンバー</h6>
            </header>
            <ul className="features">
              <li>
                <div style={{ textAlign: 'center' }}>
                  <StaticImage
                    src="../assets/images/utena.jpg" // 相対パス
                    alt="CEO"
                    placeholder="blurred" // ボヤッと読み込ませるオプション。他にもいくつかある
                    quality="40" // 画質
                    style={{
                      height: '200px',
                      width: '200px',
                      borderRadius: '50%',
                    }}
                  />
                  <h3>吉元台</h3>
                  <h4>CEO</h4>
                </div>
                <p>
                  大阪大学医学部在学中に実習を通して様々な病院などを訪れる中で医療分野におけるIT化の不十分さ、不便さを実感しそれらの改善のためにmappin株式会社を立ち上げる。JBMC(ジャパンビジネスモデルコンペティション)2021年度優勝。
                </p>
              </li>
              <li>
                <div style={{ textAlign: 'center' }}>
                  <StaticImage
                    src="../assets/images/asada.jpg" // 相対パス
                    alt="CTO"
                    placeholder="blurred" // ボヤッと読み込ませるオプション。他にもいくつかある
                    quality="40" // 画質
                    style={{
                      height: '200px',
                      width: '200px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      display: 'inline-block',
                    }} 
                  />
                  <h3>浅田祐哉</h3>
                  <h4>CTO</h4>
                </div>
                <p>
                  同大学医学部在学中に「自分の考えたものを作ってみたい」という気持ちからプログラミングを学習。研究室では機械学習を用いた研究を行う。ITで医療をより便利にするという吉元の理念に共感し、共にmappin株式会社を設立。
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="contact" className="wrapper style4">
          <div className="inner_contact">
            <header>
              <h2>CONTACT</h2>
              <br />
              <h6>お問い合わせ</h6>
              <p>
                mappinでは一緒に働いてくれる仲間や、サービスを導入したいクリニックを募集しています。お気軽にお問い合わせください。
              </p>
            </header>
            <ul className="actions stacked">
              <li>
                <a
                  href="https://tayori.com/form/4e524eb9e88032d1801ba11a30c67e59119b8f52/
                "
                  className="button fit primary"
                >
                  お問い合わせはこちら
                </a>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default IndexPage;
export const query = graphql`
  query {
    allMicrocmsNews {
      edges {
        node {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
  }
`;
